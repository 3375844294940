import React from "react";
import { graphql, Link } from "gatsby";
import Header from "../components/Header";
import PostListSimple from "../components/PostListSimple";
import SEO from "../components/seo";
import { ISEOProps } from "../types/common";
import {
  getCategoryTypeFromSring,
  getTitleByCategoryType,
} from "../utils/common";

const Container = ({ data, pageContext: { label, categoryType, theme } }) => {
  const title = getTitleByCategoryType(categoryType, label);
  const seoData: ISEOProps = {
    title: `${title}`,
    description: `${title}, ${label}`,
  };

  const postList =
    data.allMarkdownRemark.edges.length > 0
      ? data.allMarkdownRemark.edges
          .filter(v => v.node.fields.theme === theme)
          .map(({ node }: { node: any }) => {
            return {
              ...node,
            };
          })
      : [];

  const desc =
    data.allMarkdownRemark.totalCount > 0
      ? data.allMarkdownRemark.edges[0].node.fields.seriesDescription
      : "";

  return (
    <Header theme={theme}>
      <SEO {...seoData} />
      <PostListSimple
        categoryType={getCategoryTypeFromSring(categoryType)}
        postList={postList}
        title={title}
        desc={desc}
        theme={theme}
      />
    </Header>
  );
};

export const query = graphql`
  query($filters: MarkdownRemarkFilterInput!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___title, order: ASC }
      filter: $filters
    ) {
      totalCount
      edges {
        node {
          fields {
            theme
            series
            seriesDescription
            tags
            slug
            prefix(formatString: "YYYY.MM.DD")
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default Container;
